<template lang="pug">
div
  v-data-table(
    :headers="headers"
    :items="dataList"
    :items-per-page="-1"
    :loading="loading"
    loading-text="資料載入中..."
    hide-default-footer
  )
    template(v-slot:item.locationText="{ item }")
      span(v-if="item !== editingItem") {{ item.locationText }}
      select(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.location")
        option(v-for="(church, churchIndex) in clcList" :key="churchIndex" :value="church.value") {{ church.text }}
    template(v-slot:item.serialNumberStart="{ item }")
      span(v-if="item !== editingItem") {{ item.serialNumberStart }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.serialNumberStart")
    template(v-slot:item.serialNumberEnd="{ item }")
      span(v-if="item !== editingItem") {{ item.serialNumberEnd }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.serialNumberEnd")
    template(v-slot:item.registrationDate="{ item }")
      span(v-if="item !== editingItem") {{ item.registrationDate }}
      input(
        class="edit-date-input"
        type="date"
        v-if="item === editingItem"
        v-model="editingItem.registrationDate")
    template(v-slot:item.finalTicketFee="{ item }")
      span(v-if="item !== editingItem") {{ item.finalTicketFee }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.finalTicketFee")
    template(v-slot:item.actions="{ item }")
      v-btn(
        v-if="item.id && editMode === 'edit' && item === editingItem"
        @click="updateItem(item)"
        small
        dark
        color="cyan darken-4"
        class="mx-1 text-button") 儲存
      v-btn(
        v-if="item.id && item === editingItem"
        @click="cancelEditItem(item)"
        small
        dark
        color="yellow darken-4"
        class="mx-1 text-button") 取消
      v-btn(
        v-if="item.id && item !== editingItem"
        @click="edit(item)"
        small
        outlined
        color="teal"
        class="mx-1 text-button") 編輯
      v-btn(
        v-if="item.id && item !== editingItem"
        @click="handleCheckDelete(item)"
        small
        dark
        color="red darken-4"
        class="mx-1 text-button") 刪除
      v-btn(
        v-if="!item.id && item === editingItem"
        @click="addItem(item)"
        small
        dark
        color="cyan darken-4"
        class="mx-1 text-button") 新增
      v-btn(
        v-if="!item.id && item === editingItem"
        @click="cancelAddItem(item)"
        small
        dark
        color="yellow darken-4"
        class="mx-1 text-button") 取消
  v-dialog(
    v-model="deleteConfirmDialog"
    width="500"
  )
    v-card
      v-card-text(class="py-3 text-h5") 輸入 delete 確定刪除
      v-card-text
        v-text-field(
          v-model="deleteConfirm" 
          placeholder="delete"
          hide-details
        )
      v-card-actions
        v-spacer
        v-btn(
          text
          @click="deleteConfirmDialog = false") 取消
        v-btn(
          color="mx-1 red darken-4"
          :dark="deleteConfirm === 'delete'"
          :disabled="deleteConfirm !== 'delete'"
          @click="deleteEditItem") 確認刪除
</template>
<script>
import editGroup from "@/components/common/editGroup";
export default {
  name: "serialNumberSearchTable",
  props: {
    loading: {
      type: Boolean,
    },
    dataList: {
      type: Array,
    },
    addMode: {
      type: Boolean,
    },
  },
  components: {
    editGroup,
  },
  data() {
    return {
      headers: [
        {
          text: "繳費地點",
          align: "center",
          sortable: true,
          value: "locationText",
        },
        {
          text: "起始流水號",
          align: "center",
          sortable: true,
          value: "serialNumberStart",
        },
        {
          text: "結束流水號",
          align: "center",
          sortable: false,
          value: "serialNumberEnd",
        },
        {
          text: "繳費日期",
          align: "center",
          sortable: true,
          value: "registrationDate",
        },
        {
          text: "票價",
          align: "center",
          sortable: false,
          value: "finalTicketFee",
        },
        {
          text: "賣出",
          align: "center",
          sortable: false,
          value: "sellTicket",
        },
        {
          text: "金額試算",
          align: "center",
          sortable: false,
          value: "amount",
        },
        {
          text: "編輯者",
          align: "center",
          sortable: false,
          value: "updateUserName",
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      editMode: null,
      editingItem: {},
      deleteConfirmDialog: false,
      deleteConfirm: ""
    };
  },
  watch: {
    addMode() {
      const addItem = this.dataList.find((item) => {
        return !item.id;
      });
      this.editingItem = addItem;
    },
    deleteConfirmDialog(val) {
      if (!val) {
        this.deleteConfirm = ""
      }
    }
  },
  computed: {
    groups() {
      return this.$store.state.group.group;
    },
    clcList() {
      return this.$store.state.group.clcList.clcList;
    },
  },
  methods: {
    formatChurch(val) {
      const currentChurch = this.clcList.find((item) => {
        return item.text === val;
      });
      if (currentChurch) {
        return currentChurch.value;
      } else {
        return val;
      }
    },
    handleUpdateGroupId(val) {
      this.editingItem["groupId"] = val;
    },
    groupName(val) {
      const group = this.groups.find((item) => {
        return item.group_id === val;
      });
      if (group) {
        return group.group_name;
      } else {
        return "-";
      }
    },
    edit(item) {
      this.editMode = "edit";
      this.editingItem = item;
      this.editingItem.location = this.formatChurch(item.locationText);
    },
    handleCheckDelete(item) {
      this.editingItem = item;
      this.deleteConfirmDialog = true;
    },
    deleteEditItem() {
      this.$emit("delete", this.editingItem);
      this.deleteConfirmDialog = false;
      this.editingItem = {};
    },
    cancelEditItem() {
      this.editingItem = {};
    },
    updateItem() {
      this.$emit("update", this.editingItem);
      this.editingItem = {};
    },
    cancelAddItem(item) {
      this.$emit("cancelAddItem", item);
      this.editMode = null;
      this.editingItem = {};
    },
    addItem() {
      this.$emit("addItem", this.editingItem);
      this.editingItem = {};
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px;
}

.edit-component {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 100px;
}
.edit-date-input {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 180px;
}
.checkbox {
  width: fit-content;
  margin: 0 auto;
}
</style>
