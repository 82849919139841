<template lang="pug">
v-card(
  class="pa-4"
  outlined
)
  v-form(@submit.prevent="submit")
    v-row
      v-col(cols="6" md="3")
        v-select(
          v-model="searchPayload.location" 
          :items="clcList"
          label="繳費地點"
          hide-details
        )
      v-col(class="d-flex justify-space-between")
        div
          v-btn(
            @click.stop="handleSearch"
            type="submit"
            class="ma-2 font-weight-bold text-button" dark color="primary")
            v-icon(dark) mdi-magnify
            span(class="font-weight-bold") 搜尋
          v-btn(
            @click.stop="clear"
            class="ma-2 font-weight-bold text-button")
            v-icon mdi-broom
            span(class="font-weight-bold") 全部
        div
          v-btn(
            v-if="!addMode"
            @click.stop="addSerialNumber"
            class="ma-2 ml-5 font-weight-bold text-button" color="success")
            v-icon mdi-plus
            span(class="font-weight-bold") 新增
</template>

<script>
import GroupComponent from "@/components/common/group";
export default {
  name: "serialNumberSearchForm",
  components: {
    GroupComponent,
  },
  props: {
    addMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchPayload: {
        location: 0,
      },
    };
  },
  computed: {
    clcList() {
      return this.$store.state.group.clcList.clcList;
    },
  },
  methods: {
    submit() {
      this.$emit("search", this.searchPayload);
    },
    clear() {
      this.searchPayload = {
        location: 0,
      };
      this.submit();
    },
    handleSearch() {
      const payload = {
        location:
          this.searchPayload.location && this.searchPayload.location !== ""
            ? this.searchPayload.location
            : 0,
      };
      this.$emit("search", payload);
    },
    addSerialNumber() {
      this.$emit("add");
    },
  },
};
</script>
